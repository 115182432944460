import { Show, children, Component, createSignal, createMemo, For, JSXElement, splitProps, Accessor } from 'solid-js'
import { Button, Avatar, Typography, Box, Stack } from "@suid/material";
import { isExtraSmall } from "../../../utils/sizeUtil"
import { isDark } from '../AppMenuBar/AppBarToogleColorMode';
import { Looks1Icon, Looks2Icon, Looks3Icon, Looks4Icon, Looks5Icon } from "../icons"

const icons = [Looks1Icon, Looks2Icon, Looks3Icon, Looks4Icon, Looks5Icon];


////////////////////////////////
// How to use
////////////////////////////////
/*
   return (
	   <Tabs>
		   <Tab>Tab 1</Tab>
		   <Tab>Tab 2</Tab>
		   <Tab>Tab 3</Tab>
		   <Tab>Tab 4</Tab>
	   </Tabs>
   )
 */
////////////////////////////////
interface TabsProps {
	children: JSXElement,
	activeTab: number,
	setActiveTab: (index: number) => void
}

const Tabs: Component<TabsProps> = (props: TabsProps) => {
	const [local] = splitProps(props, ['children'])
	const tabs = children(() => props.children)
	const evaluatedTabs = () => tabs.toArray() as unknown as TabProps[]

	const isAccessor = (value: any): value is () => any => {
		return typeof value === 'function';
	}
	/*
		const [startX, setStartX] = createSignal(0);
		const [currentTab, setCurrentTab] = createSignal(0);
	
		const touchStart = (e: TouchEvent) => {
			setStartX(e.touches[0].clientX);
		  };
		
		  const touchMove = (e: TouchEvent) => {
			// Optional: Implement for visual feedback during swipe
		  };
		
		  const touchEnd = (e: TouchEvent) => {
			const endX = e.changedTouches[0].clientX;
			const deltaX = startX() - endX;
		
			// Determine swipe direction and change tabs
			if (deltaX > 50) { // Swiped left
			  setCurrentTab((current) => Math.min(current + 1, tabs.length - 1));
			} else if (deltaX < -50) { // Swiped right
			  setCurrentTab((current) => Math.max(current - 1, 0));
			}
		  };
		
	*/
	//<Stack direction="row" spacing={isExtraSmall() ? 0 : 1} alignItems="center" justifyContent="left" >
	return (
		<>
			<Stack
				direction="row"
				spacing={isExtraSmall() ? 0 : 1}
				alignItems="center"
				justifyContent="left"
				sx={{
					overflowX: 'auto', // Enable horizontal scrolling
					flexWrap: 'nowrap', // Prevent tabs from wrapping
					maxWidth: '90%'
					// You might need to adjust width or max-width depending on your layout
				}}
			>
				<For each={evaluatedTabs()}>
					{(child, index) => {
						const title = createMemo(() => {
							if (Array.isArray(child.children)) {
								return typeof child.children[0] === 'function' ? child.children[0]() : child.children[0];
							} else if (typeof child.children === 'function') {
								return child.children();
							} else if (isAccessor(child.children)) {
								return child.children(); // if children is an accessor, calling it should get the value
							} else {
								return child.children;
							}
						});

						const IconComponent = icons[index()]; // Get the current icon component
						const drawSmallButton = (props.activeTab + 1 === index()) || (props.activeTab - 1 === index())
						return (
							<>
								<Show
									when={isExtraSmall() && index() !== props.activeTab}
									fallback={
										<>
											<Typography
												onClick={() => props?.setActiveTab(index())}
												variant="h5"
												sx={{
													borderRadius: "50%",
													width: "16px",
													height: "16px",

													color: index() === props.activeTab ? (isDark() ? "white" : "black") : (isDark() ? "white" : "black"),
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													cursor: 'pointer',
												}}
											>
												<Box>{index() + 1}</Box>
											</Typography>
											<Button
												color={"inherit"}
												variant={index() === props.activeTab ? "text" : "text"}

												onClick={() => props?.setActiveTab(index())}
												sx={{
													borderBottom: index() === props.activeTab ? (isDark() ? "4px solid white" : "4px solid black") : "0px solid",


												}}
											>
												{title() ? title() : "No tab text"}
											</Button>
										</>
									}>

									<Box marginRight="10px">
										<Typography
											onClick={() => props?.setActiveTab(index())}
											variant="body1"
											sx={{
												borderRadius: "50%",
												width: "16px",
												height: "16px",
												backgroundColor: index() === props.activeTab ? "white" : "white",
												color: index() === props.activeTab ? "blue" : "black",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												cursor: 'pointer',
											}}
										>
											<Box>  {index() + 1}  </Box>
										</Typography>
									</Box>
								</Show>


							</>
						);
					}}
				</For>
			</Stack>

		</>
	)
}

interface TabProps {
	children: JSXElement | (() => string) | Accessor<JSXElement | undefined> | Accessor<string | undefined> | Array<JSXElement | (() => string)>;
}

const Tab: Component<TabProps> = (props) => {
	return props as unknown as JSXElement
}

export { Tabs, Tab }
